@import url(https://fonts.googleapis.com/css?family=Mansalva&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-family: 'Goudy Old Style';
    line-height: 180%;
}

@media screen and (max-width: 600px) {
  .App {
    font-family: 'Goudy Old Style';
    line-height: 180%;
  }
}

.acknowledgements {
    align-self: start;
    padding-top: 80px;
    padding-left: 280px;
    padding-right: 280px;
}

.poem {
    align-self: start;
	padding-top: 80px;
    padding-left: 180px;
}

@media screen and (max-width: 600px) {
  .poem {
    padding-top: 40px;
    padding-left: 90px;
  }
 }

.indent {
	padding-left: 1.8em;
	display: inline;
}

.indent2 {
	padding-left: 3.6em;
  display: inline;
}

.indent3 {
	padding-left: 5.4em;
  display: inline;
}

.indent4 {
  padding-left: 7.2em;
  display: inline;
}

.indent5 {
  padding-left: 9.0em;
  display: inline;
}

.indent6 {
  padding-left: 10.8em;
  display: inline;
}

.indent7 {
  padding-left: 12.6em;
  display: inline;
}

.indent8 {
  padding-left: 14.4em;
  display: inline;
}

.indent9 {
  padding-left: 16.2em;
  display: inline;
}

.indent10 {
  padding-left: 18.0em;
  display: inline;
}

.blockquote {
  padding-left: 25px;
}

.poemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.finalCycleSpan {
    background-color: white;
    color: black;
}

.text-button {
    border: none;
    padding: 3px 0;
    background-color: inherit;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    display: inline-block;
    font-style: italic;
    color: #aaa;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.text-button:hover {
    background: #eee;
}

.text-button-telemachos {
	border-bottom-color: rgba(179,0,255,0.6);
}

.text-button-penelope {
	border-bottom-color: rgba(0,130,250,0.6);
}

.text-button-odysseus {
	border-bottom-color: rgba(235,0,42,0.6);
}

.cycleSpan{
  background-color: white;
  text-decoration: underline;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  border-radius: 10px;
  padding-left: 4px;
  padding-right: 4px;
  color: rgba(100,100,100,0.9);
}

.cycleSpan:hover{
  background-color: #f8f8f8;
  color: black;
  text-decoration: none;
}

.invisible {
  color: white;
  display: inline;
}

.invisible:hover {
  color: black;
}

.setPenelope .cycleSpan{
	background-color: white;
	text-decoration: underline;
	-webkit-text-decoration-color: rgba(0,130,250,0.6);
	        text-decoration-color: rgba(0,130,250,0.6);
}

.setPenelope .cycleSpan:hover{
	background-color: rgba(170,200,255,0.2);
	color: black;
	text-decoration: none;
}

.setPenelope .hovertext {
	border-bottom-style: dotted;
  	border-bottom-color: rgba(0,130,250,0.6);
  	border-bottom-width: 2px;
}

.setOdysseus .cycleSpan{
	background-color: white;
	text-decoration: underline;
	-webkit-text-decoration-color: rgba(235,0,42,0.6);
	        text-decoration-color: rgba(235,0,42,0.6);
}

.setOdysseus .cycleSpan:hover{
	background-color: rgba(255,220,225,0.3);
	color: black;
	text-decoration: none;
}

.setOdysseus .hovertext {
	border-bottom-style: dotted;
  	border-bottom-color: rgba(235,0,42,0.6);
  	border-bottom-width: 2px;
}

.setTelemachos .cycleSpan{
  background-color: white;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(179,0,255,0.6);
          text-decoration-color: rgba(179,0,255,0.6);
}

.setTelemachos .cycleSpan:hover{
  background-color: rgba(217, 179, 252, 0.6);
  color: black;
  text-decoration: none;
}

/* Telemachos purple
correct for cyclespan underlines: rgba(179,0,255,0.6)
correct for shaded bubbles: rgba(217, 179, 252, 0.6)
*/

.setTelemachos .hovertext {
    border-bottom-style: dotted;
    border-bottom-color: rgba(179, 0, 255, 0.6);
    border-bottom-width: 1px;
}

.setTelemachos h2 .hovertext {
    border-bottom-style: dotted;
    border-bottom-color: rgba(179, 0, 255, 0.6);
    border-bottom-width: 2px;
}

.theveil {
	background-image: radial-gradient(at top left, #ffffff 50%, #eeeeff 100%);
}

.paragraph {
    background-color: antiquewhite;
}

.qr {
  border: solid;
  padding: 10px 10px 20px 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.qr img {
  height: 240px;
}

.entry {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.allthreads {
  margin: 0 auto;
}

.threadttext {
  display: inline;
}

.threadttext .cycleSpan{
  background-color: white;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(179,0,255,0.6);
          text-decoration-color: rgba(179,0,255,0.6);
}

.threadttext .cycleSpan:hover{
  background-color: rgba(217, 179, 252, 0.6);
  color: black;
  text-decoration: none;
}

.threadotext {
  display: inline;
}

.threadotext .cycleSpan{
  background-color: white;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(235,0,42,0.6);
          text-decoration-color: rgba(235,0,42,0.6);
}

.threadotext .cycleSpan:hover{
  background-color: rgba(255,220,225,0.3);
  color: black;
  text-decoration: none;
}

.threadptext {
  display: inline;
}

.threadptext .cycleSpan {
  background-color: white;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(0,130,250,0.6);
          text-decoration-color: rgba(0,130,250,0.6);
}

.threadptext .cycleSpan:hover{
  background-color: rgba(170,200,255,0.2);
  color: black;
  text-decoration: none;
}

.headrow .threadttext {
  color: rgba(179,0,255,0.6);
}

.headrow .threadotext {
  color: rgba(235,0,42,0.6);
}

.headrow .threadptext {
  color: rgba(0,130,250,0.6)
}

td {
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.intersection {
  padding-left: 10px;
  padding-right: 10px;
}

.acts {
  align-self: center;
  padding-top: 200px;
}

.heart .text-button {
  font-style: normal;
  align-self: center;
  color: red;
}
